<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  background: #f5f5f5;
  margin: 0;
  padding: 0;
}

#app {
  text-align: left;
  padding: 20px;
}
</style>
