<template>
    <div class="post-detail">
      <button @click="$router.go(-1)">Back</button>
      <h1 v-if="resolvedPost">{{ resolvedPost.attributes.title }}</h1>
      <div v-if="resolvedPost" v-html="resolvedPost.fullContent"></div>
      <div v-else class="shimmer-wrapper">Loading...</div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PostDetail",
    props: {
      post: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        loadedPost: null, // Stores the post data if fetched manually
      };
    },
    async created() {
      if (!this.post) {
        const postId = this.$route.params.id;
  
        try {
          // Fetch the post details directly
          const response = await fetch(
            `/jsonapi/node/article/${postId}`
          );
          const data = await response.json();
          this.loadedPost = {
            ...data.data,
            fullContent: data.data.attributes.body.processed,
          };
        } catch (error) {
          console.error("Failed to load post:", error);
        }
      }
    },
    computed: {
      resolvedPost() {
        return this.post || this.loadedPost;
      },
    },
  };
  </script>
  
  <style>
  .post-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .shimmer-wrapper {
    height: 200px;
    width: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 400% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  </style>
  