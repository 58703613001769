<template>
    <div class="post-list">
      <h1>Millwood Online</h1>
  
      <!-- Loading shimmer -->
      <div v-if="loading" class="shimmer-wrapper">
        <div class="shimmer"></div>
      </div>
  
      <!-- Posts list -->
      <div v-else>
        <div v-for="post in posts" :key="post.id" class="post" @click="goToPost(post)">
          <router-link :to="{ name: 'PostDetail', params: {id: post.id}}">
           <h2>{{ post.attributes.title }}</h2>
          </router-link>
          <span>{{ post.attributes.created }}</span>
          <p v-html="post.attributes.body.processed"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PostList",
    data() {
      return {
        posts: [],
        loading: true,
      };
    },
    async created() {
      try {
        const response = await fetch("/jsonapi/node/article?sort=-created");
        const data = await response.json();
        this.posts = data.data;
      } finally {
        this.loading = false;
      }
    },
    methods: {
      goToPost(post) {
        this.$router.push({ name: "PostDetail", params: { post } });
      },
    },
  };
  </script>
  
  <style>
  .post-list {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .shimmer-wrapper {
    height: 100px;
    width: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 400% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  
  .post {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .post h2 {
    margin: 0 0 10px;
  }
  </style>
  